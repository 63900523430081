import {
  TOGGLE_NEW_CATEGORY,
  GET_MY_MENUS_SUCCESS,
  API_SUCCESS,
  API_FAILURE,
  GET_MY_MENU_SUCCESS,
  API_START,
  CLEAR_API_STATUS,
  SAVE_MENU_CATEGORY_SUCCESS,
  GET_MENU_CATEGORIES_SUCCESS,
  UPDATE_MENU_CATEGORIES_SUCCESS,
  TOGGLE_NEW_GROUP,
  SAVE_MENU_GROUP_SUCCESS,
  GET_MENU_GROUPS_SUCCESS,
  SAVE_MENU_GROUP_OPTION_SUCCESS,
  UPLOAD_MENU_IMAGE_SUCCESS,
  SAVE_MENU_ITEM_SUCCESS,
  SAVE_MENU_ITEMS_SUCCESS,
  UPDATE_MENU_CATEGORY_SUCCESS,
  UPDATE_MENU,
  DELETE_MENU_SUCCCESS,
  COPY_MENU_SUCCCESS,
  UPDATE_MENU_GROUP_SUCCESS,
  DELETE_MENU_GROUP_SUCCCESS,
  DELETE_MENU_IMAGE_SUCCCESS,

} from '../actions/restaurantMenu';

import {
  UPDATE_MENU_CATEGORIES_GROUP_SUCCESS

} from '../actions/apiThunks';
import { removeItemWithSlice, updateItemsWithIndexAndItem } from 'util/utils'

const initialState = {
  showNewCategory: false,
  showNewGroup: false,
  menus: [],
  isLoading: false,
  menuCategories: [],
  menuGroups: [],
  menuImages: [],
}



const restaurantMenuReducer = (state = initialState, action) => {
  const { type, payload } = action;

  let categories;
  let categoryIdx;
  let groupIdx;

  switch (type) {
    case TOGGLE_NEW_CATEGORY:
      return { ...state, showNewCategory: !state.showNewCategory };

    case TOGGLE_NEW_GROUP:
      return { ...state, showNewGroup: !state.showNewGroup };

    case API_START:
      return { ...state, isLoading: true, err: null, apiSuccess: null };

    case GET_MY_MENUS_SUCCESS:
      return { ...state, menus: payload, isLoading: false };

    case GET_MY_MENU_SUCCESS:
      return { ...state, menu: payload, isLoading: false };

    case API_SUCCESS:
      console.log('api success');
      return { ...state, apiSuccess: true, isLoading: false };

    case API_FAILURE:
      return { ...state, err: payload, isLoading: false };

    case CLEAR_API_STATUS:
      return { ...state, err: null, apiSuccess: null };

    case SAVE_MENU_CATEGORY_SUCCESS:
      let menuCategories = [...state.menuCategories];
      menuCategories.push(payload);
      return { ...state, menuCategories };

    case SAVE_MENU_GROUP_SUCCESS:
      let menuGroups = [...state.menuGroups];
      menuGroups.push(payload);
      return { ...state, menuGroups };
    case UPDATE_MENU_GROUP_SUCCESS:
      groupIdx = state.menuGroups.findIndex(group => group.id === payload.id);
      if (groupIdx !== -1) {
        return { ...state, menuGroups: updateItemsWithIndexAndItem(state.menuGroups, groupIdx, payload) };
      } else {
        return state;
      }

    case GET_MENU_CATEGORIES_SUCCESS:
      const menu = state.menu ? state.menu : payload.menu;
      return { ...state, menuCategories: payload.categories, menu };

    case GET_MENU_GROUPS_SUCCESS:
      return { ...state, menuGroups: payload };

    case UPDATE_MENU_CATEGORIES_SUCCESS:
      return { ...state, menuCategories: payload };

    case SAVE_MENU_GROUP_OPTION_SUCCESS:
      let groups = [...state.menuGroups];
      groupIdx = groups.findIndex(item => item.id === payload.groupId);
      if (groupIdx !== -1) {
        let group = groups[groupIdx];
        if (group.options) {
          let options = [...group.options];
          options.push(payload);
          group.options = options;

        } else {
          group.options = [payload];
        }
        const newGroups = updateItemsWithIndexAndItem(groups, groupIdx, group);
        return { ...state, menuGroups: newGroups };

      } else {
        return state;
      }
    case SAVE_MENU_ITEM_SUCCESS:
      categories = [...state.menuCategories];
      categoryIdx = categories.findIndex(item => item.id === payload.categoryId);
      if (categoryIdx !== -1) {
        let category = categories[categoryIdx];
        if (category.items) {
          let items = [...category.items];
          items.push(payload);
          category.items = items;

        } else {
          category.items = [payload];
        }
        categories[categoryIdx] = category;
        return { ...state, menuCategories: categories };

      } else {
        return state;
      }

    case SAVE_MENU_ITEMS_SUCCESS:
      categories = [...state.menuCategories];
      categoryIdx = categories.findIndex(item => item.id === payload.categoryId);
      if (categoryIdx !== -1) {
        let category = categories[categoryIdx];
        category.items = payload.items;

        return { ...state, menuCategories: updateItemsWithIndexAndItem(categories, categoryIdx, category) };
      } else {
        return state;
      }

    case UPLOAD_MENU_IMAGE_SUCCESS:
      const updateIndex = state.menuImages.findIndex(item => item.menuItemId === payload.menuItemId);
      let newImages = [...state.menuImages];
      if (updateIndex !== -1) {
        newImages[updateIndex] = payload;
      } else {
        newImages.push(payload);
      }
      return { ...state, menuImages: [...newImages] };

    case DELETE_MENU_IMAGE_SUCCCESS:
        // console.log('payload.menuItemId', payload.menuItemId);
        const updateImageIndex = state.menuImages.findIndex(item => item.menuItemId === payload.menuItemId);
        let newMenuImages = [...state.menuImages];
        if (updateImageIndex !== -1) {
          newMenuImages = removeItemWithSlice(newMenuImages, updateImageIndex);
        } 
        // console.log('newMenuImages', newMenuImages);
        return { ...state, menuImages: [...newMenuImages] };
    case UPDATE_MENU_CATEGORY_SUCCESS:
      categories = [...state.menuCategories];
      categoryIdx = categories.findIndex(item => item.id === payload.id);
      if (categoryIdx !== -1) {
        categories[categoryIdx] = { ...categories[categoryIdx], ...payload };
        return { ...state, menuCategories: categories }
      } else {
        return state;
      }
    case UPDATE_MENU:
      return { ...state, menu: { ...state.menu, ...payload } }
    case COPY_MENU_SUCCCESS:
      if (payload.toRestaurantId === null) {
        return { ...state, menus: [payload.newMenu, ...state.menus] }
      } else {
        return state;
      }
      
    case DELETE_MENU_SUCCCESS:
      let newMenus = [...state.menus];
      const deleteIndx = newMenus.findIndex(menu => menu.id === payload);
      if (deleteIndx !== -1) {
        const menusList = removeItemWithSlice(newMenus, deleteIndx);
        return { ...state, menus: menusList }
      }
      return state;
    case DELETE_MENU_GROUP_SUCCCESS:
      let tempGroups = [...state.menuGroups];
      const updatedItemIndex = tempGroups.findIndex(
        item => item.id === payload
      );
      if (updatedItemIndex !== -1) {
        const newGroups = removeItemWithSlice(tempGroups, updatedItemIndex)

        return { ...state, menuGroups: newGroups }
      } else {
        return state;
      }

    case UPDATE_MENU_CATEGORIES_GROUP_SUCCESS:
      categories = [...state.menuCategories];

      payload.forEach(category => {
        categoryIdx = categories.findIndex(item => item.id === category.id);
        if (categoryIdx !== -1) {
          categories[categoryIdx] = { ...categories[categoryIdx], ...category };
        }
      });

      return { ...state, menuCategories: categories }
  

    default:
      return state;
  }
};

export default restaurantMenuReducer;
