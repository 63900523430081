import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

//import { getInitials } from 'helpers';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textLink: {
		textDecoration: 'underline',
	},
}));

const MenusTable = props => {
  const { className, menus, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedMenus, setSelectedMenus] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const sortedMenus = menus.sort((a, b) => {  // sort by createdAt
    return a.createdAt._seconds < b.createdAt._seconds ? 1 : -1;
  });
  const handleSelectAll = event => {
    

    let selectedMenus;

    if (event.target.checked) {
      selectedMenus = menus.map(menu => menu.id);
    } else {
      selectedMenus = [];
    }

    setSelectedMenus(selectedMenus);
  };

  const handleSelectOne = (event, urlIdentifier) => {
    const selectedIndex = selectedMenus.indexOf(urlIdentifier);
    let newSelectedMenus = [];

    if (selectedIndex === -1) {
      newSelectedMenus = newSelectedMenus.concat(selectedMenus, urlIdentifier);
    } else if (selectedIndex === 0) {
      newSelectedMenus = newSelectedMenus.concat(selectedMenus.slice(1));
    } else if (selectedIndex === selectedMenus.length - 1) {
      newSelectedMenus = newSelectedMenus.concat(selectedMenus.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedMenus = newSelectedMenus.concat(
        selectedMenus.slice(0, selectedIndex),
        selectedMenus.slice(selectedIndex + 1)
      );
    }

    setSelectedMenus(newSelectedMenus);
    props.updateSelectedMenu(newSelectedMenus);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getMenuType = (menu) => {
    if (menu.isMainMenu) {
      return 'Main';
    }
    if (menu.isSecondaryMenu) {
      return 'Second';
    }
    if (menu.isThirdMenu) {
      return 'Third';
    }
    if (menu.isFourthMenu) {
      return 'Fourth';
    }
    if (menu.isFifthMenu) {
      return 'Fifth';
    }
    if (menu.isSixthMenu) {
      return 'Sixth';
    }
    return 'Unknown';
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedMenus.length === menus.length}
                      color="primary"
                      indeterminate={
                        selectedMenus.length > 0 &&
                        selectedMenus.length < menus.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Menu Type</TableCell>
                  <TableCell>QR Code Type</TableCell>
                  <TableCell>Create date</TableCell>
                  <TableCell>Publish time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMenus.slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage).map(menu => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={menu.id}
                    selected={selectedMenus.indexOf(menu.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedMenus.indexOf(menu.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, menu.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell >
                      <NavLink to={`/menu/${menu.id}`}>
                        <div className={classes.textLink}>
                          <Typography variant="body1">{menu.menuName}</Typography>
                        </div>
                      </NavLink>
                    </TableCell>
                    <TableCell><Typography variant={menu.status === 'published' ? 'h5' : 'body1'} color={menu.status === 'published' ? 'primary' : 'inherit'}>{menu.status}</Typography></TableCell>
                    <TableCell>
                      {getMenuType(menu)}
                    </TableCell>
                    <TableCell>
                      {menu.qrCodeType && menu.qrCodeType}
                    </TableCell>
                    <TableCell>
                      {menu.createdAt && moment(new Date(menu.createdAt._seconds * 1000)).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {menu.publishTime && moment(new Date(menu.publishTime._seconds * 1000)).format('DD/MM HH:mm')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={menus.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

MenusTable.propTypes = {
  className: PropTypes.string,
  menus: PropTypes.array.isRequired
};

export default MenusTable;
