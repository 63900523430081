import React, { useState, useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { TwitterPicker } from "react-color";
import Select from 'react-select';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import clsx from "clsx";
import { useConfirm } from "material-ui-confirm";


import { useSelector, useDispatch } from "react-redux";
import { updateSelectedRestaurant } from "store/actions/restaurant";

import { RestaurantToolbar, RestaurantManagerList, AdyenTrustDialog } from "./components";
import API from "util/API";
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import AddressField from "components/AddressField";



const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },

  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  toolbar: theme.mixins.toolbar,
  root: {
    padding: theme.spacing(3),
  },
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  Media: {},
  select: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  }





}));

const MyRestaurant = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [altName, setAltName] = useState("");
  const [address, setAddress] = useState("");
  const [addressObject, setAddressObject] = useState(null);
  const history = useHistory();

  const [abn, setAbn] = useState("");

  const [restrauntDescription, setRestrauntDescription] = useState("");
  const [uiLoading, setUiLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [stripeFeeOnUs, setStripeFeeOnUs] = useState(false);
  const [posSurchagesOnUs, setPosSurchagesOnUs] = useState(false);

  const [urlIdentifier, setUrlIdentifier] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [takeawayPayment, setTakeawayPayment] = useState(false);
  const [serviceBell, setServiceBell] = useState(false);
  const [isBigMenuImageUsed, setIsBigMenuImageUsed] = useState(false);
  const [isDineMobileOptional, setIsDineMobileOptional] = useState(false);
  const [isPOSClientPaymentDisabled, setIsPOSClientPaymentDisabled] =
    useState(false);
  const [phone, setPhone] = useState("");
  const [longitude, setLongitude] = useState("");
  const [notificationEmail, setNotificationEmail] = useState("");
  const [latitude, setLatitude] = useState("");
  const [websiteStyle, setWebsiteStyle] = useState("simple");
  const [orderAheadDisabled, setOrderAheadDisabled] = useState(false);
  const [timezone, setTimezone] = useState("Australia/Sydney");
  const [taxName, setTaxName] = useState("");
  const [tz, setTz] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState(null);
  const [themeColor, setThemeColor] = useState("#3f51b5");
  const [isPOSOpenCashEnabled, setIsPOSOpenCashEnabled] = useState(false);

  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  const restaurants = useSelector((state) => state.restaurant.restaurants);
  const user = useSelector((state) => state.account.user);

  const timezoneOptions = moment.tz.names().map(zone => ({ label: zone, value: zone }));

  const confirm = useConfirm();

  const [openAdyenTrustDialog, setOpenAdyenTrustDialog] = useState(false);

  const handleOpenAdyenTrustDialog = () => {
    if (selectedRestaurant && selectedRestaurant.adyenPaymentSettings && selectedRestaurant.adyenPaymentSettings.legalEntityId)  {
      onboardAdyen();
      return;
    }

    setOpenAdyenTrustDialog(true);
  };

  const handleCloseAdyenTrustDialog = () => {
    setOpenAdyenTrustDialog(false);
  };

  const handleAdyenTrustFormSubmit = (formValues) => {
    const individual = {
      residentialAddress: {
        city: formValues.city,
        country: formValues.country,
        postalCode: formValues.postalCode,
        stateOrProvince: formValues.stateOrProvince,
        street: formValues.street,
      },
      name: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      },
      birthData: {
        dateOfBirth: formValues.dateOfBirth,
      },
      email: formValues.email,
      identificationData: formValues.identificationData,
      
    };

    const payload = {
      individual,
      isTrust: true,
    };
 
    onboardAdyen(payload);

    // TODO: Submit form data to Adyen Trust
    handleCloseAdyenTrustDialog();
  };

  const saveMyRestaurant = (restaurant) =>
    dispatch(updateSelectedRestaurant(restaurant));

  const deleteRestaurantLogo = async () => {
    const imageNameWithAlt = selectedRestaurant.logo
      .split("\\")
      .pop()
      .split("/")
      .pop()
      .split("?");
    const temmp =
      imageNameWithAlt.length === 2 ? imageNameWithAlt[0].split("%2f") : [];
    const imageName = temmp.length === 2 ? temmp[1] : "";
    //console.log(imageName);

    try {
      setUiLoading(true);
      await API.delete("/restaurant/image", {
        headers: {
          imageName,
        },
      });
      history.go(0);
    } catch (err) {
      setUiLoading(false);
    }
  };

  // // a function to check the current url host is orderbuddy.net.au or not
  // const isOrderBuddyProd = () => {
  //   const host = window.location.host;
  //   // console.log(host);
  //   return host === "orderbuddy.net.au";
  // };

  // useEffect(() => {
  //   const restaurantId = props.match.params.restaurantId;
  //   //console.log('restaurantId', restaurantId);
  //   if (restaurantId && restaurants && restaurants.length > 0) {
  //     const restaurantIdx = restaurants.findIndex(
  //       (item) => item.urlIdentifier === restaurantId
  //     );
  //     setSelectedRestaurant(restaurants[restaurantIdx]);
  //   }
  // }, [props, restaurants]);

  useEffect(() => {
    //console.log('selectedRestaurant', selectedRestaurant);
    if (selectedRestaurant) {
      setName(selectedRestaurant.name);
      setAltName(selectedRestaurant.altName);
      setAddress(selectedRestaurant.address);
      setRestrauntDescription(selectedRestaurant.restrauntDescription);
      setAbn(selectedRestaurant.abn);
      setUrlIdentifier(selectedRestaurant.urlIdentifier);

      if (
        selectedRestaurant.isOnline !== undefined &&
        selectedRestaurant.isOnline !== null
      ) {
        setIsOnline(selectedRestaurant.isOnline);
      } else {
        setIsOnline(true);
      }

      if (selectedRestaurant.serviceBell !== undefined) {
        setServiceBell(selectedRestaurant.serviceBell);
      }

      //console.log('takeawayPayment in selectedRestaurant', selectedRestaurant.takeawayPayment);
      if (selectedRestaurant.takeawayPayment === undefined) {
        setTakeawayPayment(true);
      } else {
        setTakeawayPayment(selectedRestaurant.takeawayPayment);
      }
      if (selectedRestaurant.themeColor) {
        setThemeColor(selectedRestaurant.themeColor);
      }

      if (selectedRestaurant.phone) {
        setPhone(selectedRestaurant.phone);
      }
      if (selectedRestaurant.stripeFeeOnUs !== undefined && selectedRestaurant.stripeFeeOnUs !== null) {
        setStripeFeeOnUs(selectedRestaurant.stripeFeeOnUs);
      }
      if (selectedRestaurant.posSurchagesOnUs !== undefined && selectedRestaurant.posSurchagesOnUs !== null) {
        setPosSurchagesOnUs(selectedRestaurant.posSurchagesOnUs);
      }

      if (selectedRestaurant.latitude !== undefined) {
        setLatitude(selectedRestaurant.latitude);
      }

      if (selectedRestaurant.longitude !== undefined) {
        setLongitude(selectedRestaurant.longitude);
      }

      if (selectedRestaurant.orderAheadDisabled !== undefined) {
        setOrderAheadDisabled(selectedRestaurant.orderAheadDisabled);
      }

      if (selectedRestaurant.isBigMenuImageUsed !== undefined) {
        setIsBigMenuImageUsed(selectedRestaurant.isBigMenuImageUsed);
      }

      if (
        selectedRestaurant.isDineMobileOptional !== undefined &&
        selectedRestaurant.isDineMobileOptional !== null
      ) {
        setIsDineMobileOptional(selectedRestaurant.isDineMobileOptional);
      }

      if (
        selectedRestaurant.isPOSClientPaymentDisabled !== undefined &&
        selectedRestaurant.isPOSClientPaymentDisabled !== null
      ) {
        setIsPOSClientPaymentDisabled(
          selectedRestaurant.isPOSClientPaymentDisabled
        );
      }

      if (
        selectedRestaurant.websiteStyle !== undefined &&
        selectedRestaurant.websiteStyle !== null
      ) {
        setWebsiteStyle(selectedRestaurant.websiteStyle);
      }

      if (
        selectedRestaurant.notificationEmail !== undefined &&
        selectedRestaurant.notificationEmail !== null
      ) {
        setNotificationEmail(selectedRestaurant.notificationEmail);
      }

      if (
        selectedRestaurant.timezone !== undefined &&
        selectedRestaurant.timezone !== null
      ) {
        setTimezone(selectedRestaurant.timezone);
      }
      if (
        selectedRestaurant.addressObject !== undefined &&
        selectedRestaurant.addressObject !== null
      ) {
        setAddressObject(selectedRestaurant.addressObject);
      }

      if (
        selectedRestaurant.latitude !== undefined &&
        selectedRestaurant.latitude !== null
      ) {
        setLatitude(selectedRestaurant.latitude);
      }

      if (
        selectedRestaurant.longitude !== undefined &&
        selectedRestaurant.longitude !== null
      ) {
        setLongitude(selectedRestaurant.longitude);
      }

      if (selectedRestaurant.posSettings &&
        selectedRestaurant.posSettings.isPOSOpenCashEnabled !== undefined &&
        selectedRestaurant.posSettings.isPOSOpenCashEnabled !== null
      ) {
        setIsPOSOpenCashEnabled(selectedRestaurant.posSettings.isPOSOpenCashEnabled);
      }
    }
  }, [restaurants, selectedRestaurant]);

  const handleErrorClose = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const getMyRestaurant = () => {
    //  authMiddleWare(props.history);
    // const authToken = localStorage.getItem('AuthToken');
    // API.defaults.headers.common['Authorization'] = authToken;
    setUiLoading(true);
    API.get("/myrestaurant")
      .then((response) => {
        //console.log(response.data);
        setUiLoading(false);
        setSelectedRestaurant(response.data);
        saveMyRestaurant(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          history.push("/sign-in");
        }
        console.log("error", JSON.stringify(error));
        setUiLoading(false);
        setErrorMsg(error.msg);
        setOpenErrorSnackbar(true);
      });
  };

  useEffect(() => {
    getMyRestaurant();
  }, [props]);

  const handleClose = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setButtonLoading(false);
    props.history.push("/myrestaurant");
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleColorChangeComplete = (chosenColor) => {
    //console.debug('chosenColor', chosenColor);
    if (chosenColor) {
      setThemeColor(chosenColor.hex);
    }
  };

  const deleteLogo = (event) => {
    event.preventDefault();
    confirm({ description: "You are about to delete the logo" })
      .then(() => {
        deleteRestaurantLogo();
      })
      .catch(() => {
        /* ... */
      });
  };

  const logoPictureHandler = (event) => {
    //console.log('restaurantId', urlIdentifier);
    event.preventDefault();
    setUiLoading(true);
    // authMiddleWare(props.history);
    // const authToken = localStorage.getItem('AuthToken');
    let form_data = new FormData();
    form_data.append("image", image);
    form_data.append("content", content);
    // API.defaults.headers.common['Authorization'] = authToken;
    API.post("/restaurant/image/" + urlIdentifier, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        selectedRestaurant.logo = response.data.imageUrl;
        //console.log('response.data.imageUrl', response.data.imageUrl);
        setSelectedRestaurant(selectedRestaurant);
        setUiLoading(false);

        history.push("/myrestaurant");
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setUiLoading(false);
        setImageError("Error in posting the data");
      });
  };

  const updateFormValues = (event) => {
    event.preventDefault();

    setButtonLoading(true);
    //axios.defaults.headers.common = { Authorization: `${authToken}` };
    const formRequest = {
      name,
      altName: altName ? altName : "",
      address: address,
      restrauntDescription: restrauntDescription,
      abn: abn,
      isOnline: isOnline,
      takeawayPayment,
      themeColor,
      serviceBell,
      stripeFeeOnUs,
      posSurchagesOnUs,
      phone,
      latitude,
      longitude,
      orderAheadDisabled,
      isBigMenuImageUsed,
      isDineMobileOptional,
      websiteStyle,
      isPOSClientPaymentDisabled,
      notificationEmail,
      timezone,
      addressObject,
      isPOSOpenCashEnabled,
    };
    if (selectedRestaurant) {
      API.put("/myrestaurant/", formRequest)
        .then(() => {
          setButtonLoading(false);
          setOpenSuccessSnackbar(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            props.history.push("/sign-in");
          }
          console.log(JSON.stringify(error));
          setButtonLoading(false);
          setErrorMsg(error.msg);
          setOpenErrorSnackbar(true);
        });
    }
  };

  const handleOrderWebsiteStyleChange = (event) => {
    setWebsiteStyle(event.target.value);
  };

  const handleSelectChange = selectedOption => {
    console.log('selectedOption', selectedOption);
    setTimezone(selectedOption.value);
  };

  const onboardUser = () => {
    //	event.preventDefault();
    setButtonLoading(true);
    API.post("/onboardUser")
      .then((result) => {
        setButtonLoading(false);
        if (result.data.url) {
          window.location.assign(result.data.url);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          props.history.push("/sign-in");
        }
        console.log(JSON.stringify(error));
        setButtonLoading(false);
        setErrorMsg(error.msg);
        setOpenErrorSnackbar(true);
      });
  };

  const onboardAdyen = async (individual) => {
    //	event.preventDefault();
    if (!addressObject) {
      setErrorMsg("Please update the address");
      setOpenErrorSnackbar(true);
      return;
    }
    setButtonLoading(true);
    try {
    const result = await API.post("/adyenOnboarding", individual ? individual : null);
      
        setButtonLoading(false);
        if (result.data.bordingLink) {
          window.location.assign(result.data.bordingLink);
        }
      } catch(error) {
        console.error(error);
        if (error.response && error.response.status === 403) {
          props.history.push("/sign-in");
        }
        setButtonLoading(false);
        setErrorMsg(error.msg);
        setOpenErrorSnackbar(true);
      };
  };

  const handleAddressChange = (newAddress) => {
    // concat address object with address string based on this format  
    // {country: '',
    //     line1: '',
    //     line2: '',
    //     line3: '',
    //     city: '',
    //     stateOrProvince: '',
    //     postalCode: '',
    // }
    const addressString = `${newAddress.line1} ${newAddress.line2} ${newAddress.line3} ${newAddress.city} ${newAddress.stateOrProvince} ${newAddress.country} ${newAddress.postalCode}`;
    setAddress(addressString);
    setAddressObject(newAddress);
    if (newAddress.location) {
      setLatitude(newAddress.location.lat);
      setLongitude(newAddress.location.lng);
    }
  };


  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        {selectedRestaurant ? (
          <RestaurantToolbar restaurant={selectedRestaurant} />
        ) : null}
        <div className={classes.content}>
          <Card className={clsx(classes.root, classes)}>
            <form autoComplete="off" noValidate>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {selectedRestaurant && selectedRestaurant.logo ? (
                    <img
                      src={selectedRestaurant.logo}
                      alt="logo"
                      height={100}
                      width={200}
                    />
                  ) : null}
                  <Grid item md={8} xs={12}>
                    <div>
                      <Typography gutterBottom variant="h4">
                        Upload Restaurant Logo
                      </Typography>
                      {selectedRestaurant && selectedRestaurant.logo ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          type="submit"
                          size="small"
                          startIcon={<DeleteIcon />}
                          className={classes.uploadButton}
                          onClick={deleteLogo}
                        >
                          Delete
                        </Button>
                      ) : null}

                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        size="small"
                        startIcon={<CloudUploadIcon />}
                        className={classes.uploadButton}
                        onClick={logoPictureHandler}
                      >
                        Upload Logo
                      </Button>
                      <input type="file" onChange={handleImageChange} />

                      {imageError ? (
                        <div className={classes.customError}>
                          {" "}
                          Wrong Image Format || Supported Format are PNG and
                          JPG
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Restaurant Name"
                      margin="dense"
                      name="name"
                      variant="outlined"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Restaurant Alt Name"
                      margin="dense"
                      name="altName"
                      variant="outlined"
                      value={altName}
                      onChange={(e) => setAltName(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={isOnline}
                          onChange={(e) => setIsOnline(e.target.checked)}
                          name="online"
                        />
                      }
                      label="Online"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Restaurant description"
                      margin="dense"
                      name="restrauntDescription"
                      variant="outlined"
                      value={restrauntDescription}
                      onChange={(e) =>
                        setRestrauntDescription(e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Address"
                      margin="dense"
                      name="address"
                      variant="outlined"
                      disabled
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <AddressField onAddressChange={handleAddressChange} addressObject={addressObject} />

                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone"
                      margin="dense"
                      name="phone"
                      variant="outlined"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Tax Number"
                      margin="dense"
                      name="abn"
                      type="number"
                      variant="outlined"
                      value={abn}
                      onChange={(e) => setAbn(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Restaurant identifier (used for order website)"
                      margin="dense"
                      name="urlIdentifier"
                      variant="outlined"
                      disabled={selectedRestaurant}
                      value={urlIdentifier}
                      onChange={(e) => setUrlIdentifier(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Select
                      className={classes.select}
                      value={{ label: timezone, value: timezone }}
                      onChange={handleSelectChange}
                      options={timezoneOptions}
                      placeholder="Select a timezone"
                    />


                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Retaurant latitude"
                      margin="dense"
                      name="latitude"
                      variant="outlined"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Retaurant longitude"
                      margin="dense"
                      name="longitude"
                      variant="outlined"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Notification Email (pickup and booking)"
                      margin="dense"
                      name="notificationEmail"
                      variant="outlined"
                      value={notificationEmail}
                      onChange={(e) => setNotificationEmail(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={takeawayPayment}
                          onChange={(e) =>
                            setTakeawayPayment(e.target.checked)
                          }
                          name="takeawayPayment"
                        />
                      }
                      label="Takeaway Online Payment"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={serviceBell}
                          onChange={(e) => setServiceBell(e.target.checked)}
                          name="serviceBell"
                        />
                      }
                      label="Service Bell"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={orderAheadDisabled}
                          onChange={(e) =>
                            setOrderAheadDisabled(e.target.checked)
                          }
                          name="orderAheadDisabled"
                        />
                      }
                      label="Only Accept Today's Order"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={isPOSOpenCashEnabled}
                          onChange={(e) =>
                            setIsPOSOpenCashEnabled(e.target.checked)
                          }
                          name="isPOSOpenCashEnabled"
                        />
                      }
                      label="POS Open Cash Enabled"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={isBigMenuImageUsed}
                          onChange={(e) =>
                            setIsBigMenuImageUsed(e.target.checked)
                          }
                          name="isBigMenuImageUsed"
                        />
                      }
                      label="Big menu item images"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={isDineMobileOptional}
                          onChange={(e) =>
                            setIsDineMobileOptional(e.target.checked)
                          }
                          name="isDineMobileOptional"
                        />
                      }
                      label="Optional Dine-in Mobile phone and guest number"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={isPOSClientPaymentDisabled}
                          onChange={(e) =>
                            setIsPOSClientPaymentDisabled(e.target.checked)
                          }
                          name="isPOSClientPaymentDisabled"
                        />
                      }
                      label="Disable POS Client Payment"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={stripeFeeOnUs}
                          onChange={(e) => setStripeFeeOnUs(e.target.checked)}
                          name="stripeFeeOnUs"
                        />
                      }
                      label="Online Payment Surcharges On Us"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={posSurchagesOnUs}
                          onChange={(e) => setPosSurchagesOnUs(e.target.checked)}
                          name="posSurchagesOnUs"
                        />
                      }
                      label="POS Payment Surcharges On Us"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Order website style
                      </FormLabel>
                      <RadioGroup
                        aria-label="menuStyle"
                        defaultValue="simple"
                        name="radio-buttons-group"
                        row
                        onChange={handleOrderWebsiteStyleChange}
                      >
                        <FormControlLabel
                          value="simple"
                          control={
                            <Radio checked={websiteStyle === "simple"} />
                          }
                          label="Simple"
                        />
                        <FormControlLabel
                          value="modern"
                          control={
                            <Radio checked={websiteStyle === "modern"} />
                          }
                          label="Modern"
                        />
                        <FormControlLabel
                          value="classic"
                          control={
                            <Radio checked={websiteStyle === "classic"} />
                          }
                          label="Classic"
                        />
                        <FormControlLabel
                          value="step"
                          control={
                            <Radio checked={websiteStyle === "step"} />
                          }
                          label="Step"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Typography variant="h6">Current Color</Typography>
                    {themeColor ? (
                      <Box bgcolor={themeColor} width={1 / 4} p={2}>
                        {" "}
                      </Box>
                    ) : null}
                    <Typography variant="h6" style={{ marginBottom: 10 }}>
                      Select a website colour (for simple style)
                    </Typography>
                    <TwitterPicker
                      colors={[
                        "#DB3E00",
                        "#FCCB00",
                        "#a8c17f",
                        "#1273DE",
                        "#c5b1ea",
                        "#f46a9f",
                        "#3f51b5",
                        "#212121",
                        "#fafafa",
                      ]}
                      color={themeColor}
                      triangle={"hide"}
                      onChangeComplete={handleColorChangeComplete}
                    />
                  </Grid>

                  {selectedRestaurant &&
                    !selectedRestaurant.stripeAccountId ? (
                    <Grid item md={6} xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onboardUser}
                        disabled={buttonLoading}
                      >
                        Setup payouts on Stripe
                        {buttonLoading && (
                          <CircularProgress
                            size={30}
                            className={classes.progess}
                          />
                        )}
                      </Button>
                    </Grid>
                  ) : null}


                  {selectedRestaurant &&
                    (!selectedRestaurant.adyenPaymentSettings || !selectedRestaurant.adyenPaymentSettings.isAdyenAccountValid || !selectedRestaurant.adyenPaymentSettings.isAllowed) ? (
                    <>
                      <Grid item md={6} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={e => onboardAdyen()}
                          disabled={buttonLoading}
                        >
                          Onboarding Adyen
                          {buttonLoading && (
                            <CircularProgress
                              size={30}
                              className={classes.progess}
                            />
                          )}
                        </Button>

                      </Grid>

                      <Grid item md={6} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenAdyenTrustDialog}
                          disabled={buttonLoading}
                        >
                          Onboarding Adyen Trust
                          {buttonLoading && (
                            <CircularProgress
                              size={30}
                              className={classes.progess}
                            />
                          )}
                        </Button>
                      </Grid>
                    </>
                  ) : null}


                </Grid>
              </CardContent>

              <CardActions />
            </form>
          </Card>
          <AdyenTrustDialog
            open={openAdyenTrustDialog}
            onClose={handleCloseAdyenTrustDialog}
            onSubmit={handleAdyenTrustFormSubmit}
          />
          <Snackbar
            open={openSuccessSnackbar}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              Saved successfully!
            </Alert>
          </Snackbar>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={2000}
            onClose={handleErrorClose}
          >
            <Alert onClose={handleErrorClose} severity="error">
              {errorMsg
                ? errorMsg
                : "Something went wrong! Please try again."}
            </Alert>
          </Snackbar>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={classes.submitButton}
            onClick={updateFormValues}
            disabled={buttonLoading || !name || !urlIdentifier}
          >
            Update Restauant
            {buttonLoading && (
              <CircularProgress size={30} className={classes.progess} />
            )}
          </Button>
        </div>
        {user && user.role &&
          (user.role === "admin" || user.role === "restaurantAdmin") ? (
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <RestaurantManagerList />
          </Grid>
        ) : null}

        
      </div>
    );
  }

};

export default MyRestaurant;
