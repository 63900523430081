import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import StepperForm from "./StepperForm";
import { StoreContextProvider } from "./Context/store";
import Typography from "@material-ui/core/Typography";
import API from "util/API";

import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
    color: "#2A8BFF",
    fontWeight: "bold",
    letterSpacing: "0.5rem",
  },
  border: {
    border: "0.15rem solid #2A8BFF",
    borderRadius: "20px",
    padding: "2%",
    width: "19rem",
    textAlign: "center",
  },
  topLayout: {
    margin: "4rem 0",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0",
    },
  },
  paperLayout: {
    padding: "1rem",

    marginTop: "5rem",
    marginBottom: "5rem",
    margin: "auto",
    border: "1px solid #ebedf0",
    borderRadius: "4px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "3rem",
    },
  },
  testLayout: {
    left: "20px",
    border: "1px solid #ebedf0",
    justifyContent: "flex-end",
  },
}));

const RestaurantBooking = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //console.log('RestaurantBooking', props, props.match);
  const restaurantId = props.match.params.restaurantId;
  const [restaurant, setRestaurant] = useState(null);
  const [disclaimer, setDisclaimer] = useState('');
  const [uiLoading, setUiLoading] = useState(false);

  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));
  const getRestaurantinfo = async () => {
    try {
      setUiLoading(true);
      const response = await API.get(
        `/restaurantInfo?restaurantId=${restaurantId}`
      );
      setUiLoading(false);
      if (response && response.data && response.data.restaurant) {
        setRestaurant(response.data.restaurant);
      }
      if (response && response.data && response.data.disclaimer) {
        setDisclaimer(response.data.disclaimer);
      }
    } catch (err) {
      setUiLoading(false);
      //console.log(err);
      showError("Something went wrong, please try it later.");
    }
  };

  useEffect(() => {
    if (restaurantId && restaurantId !== "") {
      getRestaurantinfo();
    }
  }, restaurantId);

  return (
    <Fragment>
      <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content={`${restaurant && restaurant.name} booking`}
        />
        <title>{restaurant && restaurant.name}</title>
        <link
          rel="canonical"
          href={`https://orderbuddy.net.au/restaurantBooking/${restaurantId}`}
        />
      </Helmet>
      {uiLoading ? <LinearProgress style = {{ marginBottom: 8 }}/> : null}
      <StoreContextProvider>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.topLayout}
        >
          <Grid item display="flex" md={11} xs={11}>
            <Paper className={classes.paperLayout}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <div className={classes.logo}>
                    <div className={classes.border}>
                      <Typography variant="h5">BOOKING</Typography>

                      <Typography variant="body">
                        {restaurant && restaurant.name}
                      </Typography>

                      <Typography variant="body">
                        {restaurant && restaurant.altName && restaurant.altName}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item md={12} xs={12} align="center">
                <Typography variant="body" color="primary">
                        {disclaimer}
                      </Typography>
                  </Grid>
                <Grid item md={12} xs={12}>
              
                  <StepperForm restaurantId={restaurantId} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item>
            <Typography
              variant="caption"
              align="inherit"
              classes={classes.footer}
            >
              Copyright OrderBuddy© {new Date().getFullYear()} All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </StoreContextProvider>
    </Fragment>
  );
};

export default RestaurantBooking;
