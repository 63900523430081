import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  TodaySales,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
  DishReport, 
} from './components';

import { SaleHoursReport } from '../Reports';



import LinearProgress from "@material-ui/core/LinearProgress";
import { useSelector } from "react-redux";



import { useQuery } from "react-query";
import { getTodayDashboard } from "apis/reportAPIs";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));


const Dashboard = (props) => {
  const classes = useStyles();
  
  const user = useSelector((state) => state.account.user);
  const currencySymbol = (user && user.currencySymbol) ? user.currencySymbol : "$";

  const { data, isLoading, error } = useQuery({
    queryKey: 'todaySalesReport',
    queryFn: () => getTodayDashboard(),
    enabled: user && user.role !== 'restaurantManager',
    cacheTime: 5 * 60 * 1000, // 5 minutes
  });

  if (user && user.role === 'restaurantManager') {
    return (
      <div className={classes.root}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} textAlign="center">
            <h1>Welcome, Restaurant Manager!</h1>
            <p>Please use the navigation menu to access your restaurant management features.</p>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {isLoading ? <LinearProgress style = {{ marginBottom: 8 }}/> : null}
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TodaySales todayData={data ? data.todaySales : 0} currencySymbol={currencySymbol}/>
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers todayCash={data ? data.todayCash : 0} currencySymbol={currencySymbol} />
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress todayData={data ? data.todayRefunds : 0} currencySymbol={currencySymbol}/>
        </Grid>

  

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit weekTotalSales={data ? data.weekTotalSales : 0} currencySymbol={currencySymbol}/>
        </Grid>

       

        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={12}
        >
          <SaleHoursReport weekReports={data ? data.weekReports : []} currencySymbol={currencySymbol} />
        </Grid>

       

        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={12}
        >
          <LatestSales weekReports={data ? data.weekReports : []} currencySymbol={currencySymbol} />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <DishReport  user={user} currencySymbol={currencySymbol}/>
        </Grid>
       
     
      </Grid>
    </div>
  );
};

export default Dashboard;
