import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';


import {  useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';


import { showSuccessSnackbar, showErrorSnackbar } from 'store/actions/snackbarMessage'
import { publishMenu, publishSecondaryMenu, unpublishQRMenu } from 'store/actions/restaurantMenu';
import { useConfirm } from 'material-ui-confirm';
import API from 'util/API';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5)
  },
  spacer: {
    flexGrow: 1
  },
  buttonGroup: {
    display: 'flex',
    gap: theme.spacing(1.5)
  },
  button: {
    minWidth: '140px'
  },
  publishButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  unpublishButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

const MenuToolbar = props => {
  const { className, menu, menuId, ...rest } = props;
  
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const publishTheMenu = (id, qrCodeType) => dispatch(publishMenu(id, qrCodeType));
  const unpublishTheQRMenu = (id, qrCodeType) => dispatch(unpublishQRMenu(id, qrCodeType));
  
  const publishTheSecondaryMenu = (id) => dispatch(publishSecondaryMenu(id));

  const classes = useStyles();

  const handlePublish = () => {
    if (menu.isMainMenu === false) {
      publishTheSecondaryMenu(menuId);
    } else {
      confirm({ 
        title: 'Publish Menu',
        description: 'You are about to publish this menu to the website. Are you sure?',
        confirmationText: 'Publish',
        cancellationText: 'Cancel'
      })
        .then(() => {
          publishTheMenu(menuId);
        })
        .catch(() => { /* ... */ });
    }
  };

  const handlePublishOnline = () => {
    if (menu.qrCodeType.length === 0) {
      showError('Please select a QR code type for this menu.')
      return;
    } else {
      confirm({ 
        title: 'Publish QR Menu',
        description: 'You are about to publish this menu online with QR code access. Continue?',
        confirmationText: 'Publish',
        cancellationText: 'Cancel'
      })
        .then(() => {
          publishTheMenu(menuId, menu.qrCodeType);
        })
        .catch(() => { /* ... */ });
    }
  };

  const handleUnpublish = () => {
    if (menu.qrCodeType.length === 0) {
      showError('Please select a QR code type for this menu.')
      return;
    } else {
      confirm({ 
        title: 'Unpublish QR Menu',
        description: 'You are about to unpublish this menu from online QR code access. Continue?',
        confirmationText: 'Unpublish',
        cancellationText: 'Cancel'
      })
        .then(() => {
          unpublishTheQRMenu(menuId, menu.qrCodeType);
        })
        .catch(() => { /* ... */ });
    }
  };

  const handleOpitonLinksRefresh = async () => {
    try {
      await API.post('/refreshOptionLinks/' + menuId);
      showSuccessMessage('Option links refreshed successfully');
    } catch (err) {
      showError('Something went wrong, please try again.');
    }
  };



  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <div className={classes.buttonGroup}>
          <Button 
            variant="contained" 
            color="primary" 
            className={classes.button}
            onClick={handleOpitonLinksRefresh}
            startIcon={<i className="material-icons">refresh</i>}
          >
            Options
          </Button>
          
          <Button 
            variant="contained" 
            className={clsx(classes.button, classes.publishButton)}
            onClick={handlePublish}
            startIcon={<i className="material-icons">publish</i>}
          >
            Main
          </Button>
          
          <Button 
            variant="contained" 
            className={clsx(classes.button, classes.publishButton)}
            onClick={handlePublishOnline}
            startIcon={<i className="material-icons">qr_code</i>}
          >
            Publish
          </Button>
          
          <Button 
            variant="contained" 
            className={clsx(classes.button, classes.unpublishButton)}
            onClick={handleUnpublish}
            startIcon={<i className="material-icons">unpublished</i>}
          >
            QR Code
          </Button>

          <NavLink to={'/menuCookingCategoryList'}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<i className="material-icons">Cooking</i>}
            >
               Categories
            </Button>
          </NavLink>

          <NavLink to={'/menuCategory/' + props.menuId}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<i className="material-icons">Menu</i>}
            >
             Categories
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

MenuToolbar.propTypes = {
  className: PropTypes.string
};

export default MenuToolbar;
