import { useState } from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import React, { useEffect } from "react";
import {  useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showErrorSnackbar } from "store/actions/snackbarMessage";

import { makeStyles } from "@material-ui/core/styles";


import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  pay: {
    margin: theme.spacing(1),
  },
}));


function getQueryParam(param) {
	const queryParams = new URLSearchParams(window.location.search);
	return queryParams.get(param); // Replace 'param' with the actual query parameter name
  }

const OBAdyenSessionPayment = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);


  const sessionDetails = getQueryParam('paymentDetails');
  console.log("sessionDetails", sessionDetails);

  const sessionId = getQueryParam('sessionId');
  const sessionPayload = JSON.parse(Buffer.from(sessionDetails, "base64").toString());
  const handleSuccessResult = () => {
    console.log("Payment completed");
    const referenceNumber = sessionPayload.referenceNumber;

    // use url redirect
     window.location.href = `/obPaymentSuccess/${referenceNumber}`;
    
    

    //  history.push(`/obPaymentSuccess/${orderNumber}`);

  };





  const googlePayConfiguration = {
    amount: {
        value: (Number(sessionPayload.total) * 100).toFixed(0),
        currency: sessionPayload.currency
    },
    countryCode: sessionPayload.countryCode || "AU",
    //Set this to PRODUCTION when you're ready to accept live payments
    environment: sessionPayload.environment
};

  const configuration = {
    environment: sessionPayload.environment,
    session: {
      id: sessionId || sessionPayload.id,
      sessionData: sessionPayload.sessionData,
    },
    clientKey: sessionPayload.clientKey,
    locale: sessionPayload.locale || "en_AU",
    analytics: {
      enabled: false, // Set to false to not send analytics data to Adyen.
    },
    paymentMethodsConfiguration: {
      applepay: {
        amount: {
          value: (Number(sessionPayload.total) * 100).toFixed(0),
          currency: sessionPayload.currency,
        },
        countryCode: sessionPayload.countryCode || "AU",
      },
      googlepay: googlePayConfiguration,
      card: {
        showPayButton: true,
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: false,
        amount: {
          value: (Number(sessionPayload.total) * 100).toFixed(0),
          currency: sessionPayload.currency
            ? sessionPayload.currency
            : "AUD",
        },
      },
    },

    onPaymentCompleted: (result, component) => {
      // check result.resultCode
      if (result.resultCode === "Authorised") {
        handleSuccessResult();
      } else {
        showError("Payment failed or was cancelled");
        history.goBack();
      }
  },
    
  

    onError: (error, component) => {
      console.error(error.name, error.message, error.stack, component);
      prepareForPayment();
    },
  };

  const prepareForPayment = async () => {
    console.log("configuration", configuration);
    const checkout = await AdyenCheckout(configuration);
    
    if (!document.getElementById("dropin-container")) {
      return;
    }

    // Check for redirectResult in the URL
    const redirectResult = getQueryParam('redirectResult');
    if (redirectResult) {
      // Handle the redirect result
      checkout.submitDetails({
        details: { redirectResult }
      });
    }
    console.log("checkout", checkout);

    // If no redirectResult, mount the dropin component
    checkout.create("dropin").mount("#dropin-container");
  };

  useEffect(() => {
    // console.log(order);
    if (sessionPayload) {
      const container = document.getElementById("dropin-container");
      if (container) {
        prepareForPayment();
      }
    }
  }, [sessionPayload]);

  const goBack = (e) => {
    props.history.goBack();
  };

  // get order number last 4 characters with uppercase
  const orderNumber = () => {
    return sessionPayload.referenceNumber
      ? sessionPayload.referenceNumber
      : "";
  };

  return (

    <div className={classes.root}>
      <Helmet>
        <script
          src="https://pay.google.com/gp/p/js/pay.js"
          type="text/javascript"
        />
      </Helmet>
      <div id="dropin-container" className={classes.pay}></div>
    </div>
  );
};

export default OBAdyenSessionPayment;
